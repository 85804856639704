/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
}

mat-cell > span.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
